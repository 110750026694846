import type { Field } from 'formular'
import React, { useEffect, useMemo, useState } from 'react'
import useImageUpload from 'models/mapLayer/useImageUpload'
import { modalVisibility, ModalVisibilityProps, openModal } from 'modal'

import ReactCrop from 'local_modules/react-image-crop'
import 'local_modules/react-image-crop/ReactCrop.scss'

import { Modal } from 'components/feedback'
import { Text } from 'components/dataDisplay'
import { openSubmitFailedModal } from 'compositions/modals'

import { useCrop } from 'layers/immortals/pages/HomePage/components/CropPhotoModal/util'


type UploadPhotoModalProps = {
  fileName: string
  imageBlob: Blob
  field: Field<string> // field with image url
  message: string
  aspectRatio?: number // '16/10' = 1.6 | '1/1' = 1
  uploadUrl?: string
  circularCrop?: boolean
}

const UploadPhotoModal: React.FC<UploadPhotoModalProps & ModalVisibilityProps> = (props) => {
  const { field, imageBlob, message, fileName, aspectRatio, circularCrop = true, uploadUrl, closeModal } = props

  const imageBlobUrl = useMemo(() => URL.createObjectURL(imageBlob), [ imageBlob ])

  const {
    crop,
    pixelCrop,
    isImageLoaded,
    getCroppedImageBlob,
    onImageLoad,
    onCropChange,
  } = useCrop({ aspect: aspectRatio })

  const [ croppedImage, setCroppedImage ] = useState<Blob>()

  useEffect(() => {
    if (isImageLoaded) {
      getCroppedImageBlob().then(setCroppedImage)
    }

  }, [ isImageLoaded, getCroppedImageBlob ])

  const { error, imageUrl, isUploaded, submitUpload } = useImageUpload(fileName, croppedImage, -180, uploadUrl)

  useEffect(() => {
    if (isUploaded) {
      field.set(imageUrl)
      closeModal()
    }
  }, [ field, imageUrl, isUploaded, closeModal ])

  useEffect(() => {
    if (error) {
      openSubmitFailedModal({
        title: 'Ошибка загрузки изображения',
        text: error,
      })

      closeModal()
    }
  }, [ field, error, closeModal ])

  useEffect(() => {
    return () => URL.revokeObjectURL(imageBlobUrl)
  }, [ imageBlobUrl ])

  return (
    <Modal
      className="justify-start"
      title=""
      closeModal={closeModal}
      secondaryButton={{
        className: 'mt-40px',
        title: 'Сохранить',
        fullWidth: true,
        onClick: submitUpload,
      }}
      fullWidth
    >
      <div className="flex justify-center">
        <ReactCrop
          style={{ maxWidth: '80%' }}
          crop={pixelCrop}
          aspect={aspectRatio}
          circularCrop={circularCrop}
          keepSelection
          onChange={onCropChange}
        >
          <img style={{ maxHeight: '80vh', maxWidth: '100%' }} src={imageBlobUrl} onLoad={onImageLoad} />
        </ReactCrop>
      </div>
      <Text
        className="mt-24px opacity-72 text-center"
        message={message}
        size="t16-20"
      />
    </Modal>
  )
}

export const openUploadPhotoModal = (props: UploadPhotoModalProps) => openModal('uploadPhotoModal', props)


export default modalVisibility('uploadPhotoModal', UploadPhotoModal)