import { useMutation } from 'hooks'
import { useCallback, useState } from 'react'


type State = {
  error: string | null
  imageUrl: string
  isUploaded: boolean
}

const useImageUpload = (fileName: string, imageBlob: Blob, orderId: number, uploadUrl?: string) => {
  const [ { error, imageUrl, isUploaded }, setState ] = useState<State>({
    error: null,
    imageUrl: '',
    isUploaded: false,
  })

  const [ submitPhotoUpload ] = useMutation<{ files }>({
    url: `/uploadfile/index.php`,
    headers: { 'Content-Type': 'multipart/form-data' },
  })

  const submitUpload = useCallback(() => {
    if (imageBlob) {
      const formData = new FormData()
      formData.append('orderId', String(orderId))
      formData.append('renameShort', '0')
      formData.append('files', imageBlob, fileName)

      if (uploadUrl) {
        formData.append('upload_url', uploadUrl)
      }

      submitPhotoUpload(formData).then((response) => {
        const { files: uploadedFiles } = response.data

        const { url, error } = uploadedFiles[ 0 ]

        if (error) {
          setState({ error, imageUrl: '', isUploaded: false })
        }
        else {
          setState({ error: null, imageUrl: url, isUploaded: true })
        }
      })
    }
  }, [ fileName, imageBlob, orderId, submitPhotoUpload, uploadUrl ])

  return {
    error,
    imageUrl,
    isUploaded,
    submitUpload,
  }
}

export default useImageUpload