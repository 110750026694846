import React from 'react'

import { ActionButton } from 'components/inputs'
import { Text, Icon, IconName, IconColor } from 'components/dataDisplay'
import { Notification, NotificationProps } from 'components/feedback'


type PlainNotificationProps = NotificationProps & {
  title: string
  text?: string
  icon: IconName
  iconColor?: IconColor | 'inherit'
  button?: {
    title: string
    icon: IconName
    to?: string
    onClick?: () => void
  }
}

const PlainNotification: React.FC<PlainNotificationProps> = (props) => {
  const { title, text, icon, button, closeNotification, iconColor = 'rocky' } = props

  return (
    <Notification
      className="flex items-center"
      closeNotification={closeNotification}
    >
      <div className="flex flex-1">
        <div className="flex items-center" style={{ height: '20px' }}>
          <Icon name={icon} color={iconColor} />
        </div>
        <div className="flex flex-col ml-8px">
          <Text size="t16-20" message={title} color="harakiri" />
          <Text className="mt-8px" size="t16-20" message={text} html dataTestId="text" />
        </div>
      </div>
      {
        Boolean(button) && (
          <ActionButton
            className="ml-48px"
            title={button.title}
            color="rocky"
            icon={button.icon}
            to={button.to}
            onClick={button.onClick}
          />
        )
      }
    </Notification>
  )
}


export default PlainNotification
