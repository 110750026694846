import React from 'react'

import {
  CommonModal,
  LoginModal,
  SearchModal,
  ConfirmModal,
  SupportModal,
  FullScreenModal,
  SubmitFailedModal,
  SubmitSucceededModal,
} from 'compositions/modals'
import UploadPhotoModal from 'compositions/modals/UploadPhotoModal/UploadPhotoModal'


const Modals = () => (
  <>
    <CommonModal />
    <LoginModal />
    <SearchModal />
    <SupportModal />
    <ConfirmModal />
    <FullScreenModal />
    <SubmitFailedModal />
    <UploadPhotoModal />
    <SubmitSucceededModal />
  </>
)


export default Modals
