import React, { useCallback, useRef, useState } from 'react'
import { canvasToBlob } from 'helpers/modifiers'
import {
  Crop,
  centerCrop,
  convertToPixelCrop,
  makeAspectCrop, PixelCrop,
} from 'local_modules/react-image-crop'

import { canvasPreview, imagePreview } from '.'


type UseCropProps = {
  aspect?: number
}

const useCrop = ({ aspect = 1 }: UseCropProps) => {
  const [ pixelCrop, setPixelCrop ] = useState<PixelCrop>()
  const [ isImageLoaded, setImageLoaded ] = useState(false)
  const [ percentCrop, setPercentCrop ] = useState<Crop>({
    x: 0,
    y: 0,
    width: 100,
    height: 100 * aspect,
    unit: '%',
  })

  const imgRef = useRef<HTMLImageElement>()

  const getCroppedImage = useCallback(() => {
    return imagePreview(
      imgRef.current,
      pixelCrop,
      1,
      0
    )
  }, [ pixelCrop ])

  const getCroppedImageBlob = useCallback(async () => {
    const canvas = document.createElement('canvas')
    canvasPreview(imgRef.current, canvas, pixelCrop, 1, 0)
    imgRef.current
    const blob = await canvasToBlob(canvas, 'image/png')
    return blob
  }, [ pixelCrop ])

  // center crop area
  const onImageLoad = useCallback((e: React.SyntheticEvent<HTMLImageElement>) =>{
    imgRef.current = e.currentTarget

    const { width, height } = e.currentTarget

    const size = width > height ? 'height' : 'width'

    const percentCrop = centerCrop(
      makeAspectCrop(
        {
          unit: '%',
          [size]: 90,
        },
        aspect,
        width,
        height
      ),
      width,
      height
    )

    const pixelCrop = convertToPixelCrop(
      percentCrop,
      width,
      height
    )

    setPixelCrop(pixelCrop)
    setPercentCrop(percentCrop)
    setImageLoaded(true)
  }, [ aspect ])

  const onCropChange = useCallback((pixelCrop, percentCrop) => {
    setPixelCrop(pixelCrop)
    setPercentCrop(percentCrop)
  }, [])

  return {
    crop: percentCrop,
    pixelCrop,
    isImageLoaded,
    getCroppedImage,
    getCroppedImageBlob,
    onImageLoad,
    onCropChange,
  }
}


export default useCrop
